<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData:any
}>();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref();
const section2TextRef = ref<HTMLParagraphElement | null>(null);
const section3TextRef = ref<HTMLParagraphElement | null>(null);
const section2ImgRef = ref<HTMLDivElement | null>(null);
const section3ImgRef = ref<HTMLDivElement | null>(null);
const menuItems = [
  {
    label:'qm_origin_stories_menu_origin_story',
    id:'origin-stories-section'
  }
];

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};



onMounted(() => {
  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
  recalculateHeight();
  window.addEventListener('resize', recalculateHeight);
});
onBeforeUnmount(()=>{
  window.removeEventListener('resize', recalculateHeight);
});

function recalculateHeight(){
  requestAnimationFrame(()=>{
    if(section2TextRef.value){
      let height = section2TextRef.value.getBoundingClientRect().height;
      section2ImgRef.value?.style.setProperty('--text-height', `${height}px`);
    }

    if(section3TextRef.value){
      let height = section3TextRef.value.getBoundingClientRect().height;
      section3ImgRef.value?.style.setProperty('--text-height', `${height}px`);
    }
  });
}
</script>

<template>
  <div :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :qm-navigation="true"
    />
    <qm-secondary-navigation
      :menu-items-data="menuItems"
    />
    <div class="tw-relative">
      <picture>
        <img
          :src="$cdn('images/qm2024/banner.scaled.jpg')"
          class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
        >
      </picture>
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div class="tw-text-white tw-text-center">
          <div
            class="tw-text-sg-h1-mobile-qm sgTablet:tw-text-sg-h1-tablet-qm sgDesktop:tw-text-sg-h1-qm tw-pb-1"
            :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
          >
            {{ $t('qm_origin_stories_title') }}
          </div>
        </div>
      </div>
    </div>

    <div
      id="origin-stories-section"
      class="tw-bg-gradient-to-t tw-from-sg-qm25-light-orange tw-to-sg-qm24-light-green-w60 tw-relative"
    >

      <div class="tw-absolute tw-top-[500px] tw-left-0 tw-hidden sgTablet:tw-block">
        <img
          :src="$cdn('images/qm2024/origin-stories/floatingMountainLeft.png')"
          class="tw-w-[100px] sgDesktop:tw-w-[160px]"
        >
      </div>
      <div class="tw-absolute tw-bottom-[500px] tw-right-0 tw-hidden sgTablet:tw-block">
        <img
          :src="$cdn('images/qm2024/origin-stories/floatingMountainRight.png')"
          class="tw-w-[100px] sgDesktop:tw-w-[160px]"
        >
      </div>
      <div class="tw-max-w-full sgTablet:tw-max-w-[min(800px,100vw-200px)] sgDesktop:tw-max-w-[min(800px,100vw-320px)] tw-w-full tw-mx-auto tw-px-4 sgTablet:tw-px-0 tw-py-5 sgTablet:tw-py-10">
        <section
          class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider"
        >
          <p>
            {{ $t('qm_origin_stories_section_1_desc_1') }}
          </p>
          <p class="tw-mt-6">
            {{ $t('qm_origin_stories_section_1_desc_2') }}
          </p>
        </section>

        <section
          class="tw-mt-6
        tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider
        "
        >
          <div

            class="tw-grid tw-grid-cols-1 sgTablet:tw-grid-cols-2 tw-gap-6"
          >
            <div
              ref="section2ImgRef"
              :style="{
                '--text-height':'0px'
              }"
              class="tw-col-span-1 tw-flex"
            >
              <img
                :src="$cdn('images/qm2024/origin-stories/mountain.png')"
                class="tw-place-self-center tw-w-full tw-object-contain tw-h-[300px] sgTablet:tw-h-auto tw-max-h-[384px]"
                :class="{
                  // '!tw-max-h-[216px]': checkLang() == 'zh-hans'
                }"
              >
            </div>
            <div class="tw-col-span-1 tw-flex tw-flex-col tw-gap-6">
              <p
                ref="section2TextRef"
              >
                {{ $t('qm_origin_stories_section_2_desc') }}
              </p>
              <p ref="section3TextRef">
                {{ $t('qm_origin_stories_section_3_desc') }}
              </p>
            </div>
          </div>
        </section>
        <div class="tw-flex tw-flex-col-reverse sgTablet:tw-grid sgTablet:tw-grid-cols-2 tw-gap-6">
          <!-- text -->
          <section
            class="tw-mt-6 tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider"
          >
            <p ref="section3TextRef">
              {{ $t('qm_origin_stories_section_4_desc') }}
              <span><sup
                class="tw-text-[#0366D8] tw-cursor-pointer"
                @click="handleOpenImageSourceModal"
              >[1]</sup></span>
            </p>
          </section>
          <!-- image -->
          <div
            ref="section3ImgRef"
            :style="{
              '--text-height':'0px'
            }"
            class="tw-col-span-1 tw-flex tw-order-1 sgTablet:tw-order-2"
          >
            <img
              :src="$cdn('images/qm2024/origin-stories/tomb_v2.png')"
              class="tw-place-self-center tw-w-full tw-object-contain tw-h-[300px] sgTablet:tw-h-auto tw-max-h-[264px]"
              :class="{
                // '!tw-max-h-[121px] tw-mt-6': checkLang() == 'zh-hans'
              }"
            >
          </div>
        </div>
        <section
          class="tw-mt-6
        tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider
        "
        >
          <p v-html="$t('qm_origin_stories_section_5_desc')" />
        </section>
      </div>
    </div>


    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>
